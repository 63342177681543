import React, { Component, Fragment } from 'react';
import FooterComponent from "../Footer/FooterComponent";
import HeaderComponent from "../Header/HeaderComponent";
import BannerSliderComponent from "./BannerSliderComponent";
import WhatWeDoComponent from "../../User/Homepage/WhatWeDo/WhatWeDoComponent";
import H2Title from "../Element/Heading";
import SingleNewProduct from "./SingleNewProduct";
import * as service from "../../../Service/service";
import * as endpoint from "../../../Service/endpoints";
import ProductSurveyModal from './ProductSurveyModal';


class ProductHompage extends Component {
    state = {
        values: [
          {
            id: 1,
            quality: "Quality Rentals at Best Prices",
            icon: "ribbon",
          },
          {
            id: 2,
            quality: "Easy Customization & Returns",
            icon: "extension",
          },
          {
            id: 3,
            quality: "Free Delivery & Maintenance",
            icon: "construct",
          },
          {
            id: 4,
            quality: "Flexible Rental Plans",
            icon: "settings",
          },
          {
            id: 5,
            quality: "24/7 Customer Support",
            icon: "chatbubbles",
          },
        ],
        products: [],
        category: [],
        subCategory: [],
        categoryValue: '',
        subCategoryValue: ''
      };
    
      componentDidMount() {

        this.listAllProducts("","all");

        service.getNewProductsCategory().then(
            (response) => {
            console.log(response.data)
              this.setState({
                ...this.state,
                category: response.data,
              });

            },
            (error) => {
              console.log(error);
            }
          );

          service.getNewProductsSubCategory().then(
            (response) => {
              this.setState({
                ...this.state,
                subCategory: response.data,
              });
            },
            (error) => {
              console.log(error);
            }
          );
      }

      searchByCategory = (event) => {
        this.setState(
          {
            ...this.state,
            categoryValue: event.target.value,
            subCategoryValue:'',
          },
          () => {
            this.state.categoryValue!=='' ? this.listAllProducts(this.state.categoryValue,"category") : this.listAllProducts("","all");
            console.log(this.state);
          }
        );
      };

      searchBySubCategory = (event) => {
        this.setState(
          {
            ...this.state,
            subCategoryValue: event.target.value,
            categoryValue:'',
          },
          () => {
            this.state.subCategoryValue!=='' ? this.listAllProducts(this.state.subCategoryValue,"subcategory") : this.listAllProducts("","all");
            console.log(this.state);
          }
        );
      };

      listAllProducts = (category,type) => {

        service.getAllNewProducts(category,type).then(
          (response) => {
            this.setState({
              ...this.state,
              products: response.data,
            });
          },
          (error) => {
            console.log(error);
          }
        );

      }

      resetSearch = ()=>{
        this.setState({
          categoryValue:'',
          subCategoryValue:''
        })
        this.listAllProducts("","all");
      }
    
      render() {
        return (
          <React.Fragment>

            <HeaderComponent />
    
            <BannerSliderComponent />
    
            <div className="d-none d-sm-block">
              <div
                className="row text-center"
                style={{
                  backgroundImage: "linear-gradient(270deg,rgb(255, 255, 255),rgb(255, 255, 255))",
                  minHeight: "105px",
                }}
              >
                {this.state.values.map((value, index) => (
                  <WhatWeDoComponent
                    key={index}
                    quality={value.quality}
                    icon={value.icon}
                  />
                ))}
              </div>
            </div>
    
            <main role="main" className="container m-auto">
    
              <div className="row mt-4">
                <div className="col-12 mb-4">
                  <H2Title value={"New Products"} />
                        
                  <div className="col-12">
                        <select
                            value={this.state.subCategoryValue}
                            onChange={this.searchBySubCategory}
                            style={{minWidth:"145px"}}
                            className="browser-default custom-select col-1 float-right"
                            >
                                <option value=''>Sub-Category</option>
                                {this.state.subCategory.map(
                                    (sub, index) =>
                                    <option>{sub}</option>
                                )}
                        </select>

                        <select
                        value={this.state.categoryValue}
                        onChange={this.searchByCategory}
                        style={{minWidth:"110px"}}
                        className="browser-default custom-select col-1 float-right mr-2"
                        >
                            <option value=''>Category</option>
                            {this.state.category.map(
                                (cat, index) =>
                                <option>{cat}</option>
                            )}
                        </select>
                        {
                          (this.state.categoryValue!=='' || this.state.subCategoryValue!=='') && (
                            <button className="btn btn-danger float-right mr-2" onClick={this.resetSearch}>Reset</button>
                          ) 
                        }

                        </div>
                        </div>


                        <div className="row">
                          {this.state.products.length != 0 &&
                            this.state.products.map(
                              (prod, index) =>
                                
                                  <SingleNewProduct
                                    key={index}
                                    product={prod}
                                    styleValue={{ padding: "" }}
                                  />
                                
                            )}
                        </div>
                        <ProductSurveyModal/>
                
              </div>

            </main>
    
            <FooterComponent />
          </React.Fragment>
        );
      }
    }
    
    export default (ProductHompage);