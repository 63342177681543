import React, { Component } from 'react';
import H2Title from './../../../Generic/Element/Heading';
import ReactHtmlParser from 'react-html-parser';
import * as service from './../../../../Service/service';
import validator from 'validator';
import { connect } from 'react-redux';

class RegisterComponent extends Component {

    state = {
        userData: {
            username: {
                verified: false,
                value: '',
                touched: false,
                error: ''
            },
            otp: {
                verified: false,
                value: '',
                touched: false,
                error: ''
            },
            mobile: {
                verified: false,
                value: '',
                touched: false,
                error: ''
            },
            name: {
                verified: false,
                value: '',
                touched: false,
                error: ''
            },
            email: {
                verified: false,
                value: '',
                touched: false,
                error: ''
            },
        },
        uuid: {
            value: '',
            message: '',
            verified: false,
        },
        onClickDisable: false,
        otpClicked: false,
        isDisabled: false,
        showOtpInput: false,
        isVerified: false,
        countdown: 0,
        otpButtonDisabled: false,
        isError: {
            verified: false,
            value: ''
        },
        isRegisterError: {
            verified: false,
            value: ''
        },
    };

    validateName = () => {
        this.setState({
            userData: {
                ...this.state.userData,
                name: {
                    ...this.state.userData.name,
                    error: this.state.userData.name.value.length >= 4 ? '' : `<div style="display:block" class="invalid-feedback">Name must be at least 4 characters long</div>`,
                    verified: this.state.userData.name.value.length >= 4
                }
            }
        });
    };

    validateMobile = () => {
        this.setState({
            userData: {
                ...this.state.userData,
                mobile: {
                    ...this.state.userData.mobile,
                    error: validator.isMobilePhone(this.state.userData.mobile.value, ['en-IN']) ? '' : `<div style="display:block" class="invalid-feedback">Invalid Mobile number</div>`,
                    verified: validator.isMobilePhone(this.state.userData.mobile.value, ['en-IN'])
                }
            }
        });
    };

    checkUsername = () => {
        if (this.state.userData.username.value.length < 4) {
            this.setState({
                userData: {
                    ...this.state.userData,
                    username: {
                        ...this.state.userData.username,
                        error: `<div style="display:block" class="invalid-feedback">Username must be at least 4 characters long</div>`,
                        verified: false
                    }
                }
            });
            return;
        }

        service.checkUsername(this.state.userData.username.value).then(response => {
            this.setState({
                userData: {
                    ...this.state.userData,
                    username: {
                        ...this.state.userData.username,
                        error: response.data === false ? '' : `<div style="display:block" class="invalid-feedback">Username already exists.</div>`,
                        verified: response.data === false
                    }
                }
            });
        }).catch(error => console.log(error));
    };

    checkEmail = () => {
        if (!validator.isEmail(this.state.userData.email.value)) {
            this.setState({
                userData: {
                    ...this.state.userData,
                    email: {
                        ...this.state.userData.email,
                        error: `<div style="display:block" class="invalid-feedback">Invalid email format</div>`,
                        verified: false
                    }
                }
            });
            return;
        }

        service.checkEmail(this.state.userData.email.value).then(response => {
            this.setState({
                userData: {
                    ...this.state.userData,
                    email: {
                        ...this.state.userData.email,
                        error: response.data === false ? '' : `<div style="display:block" class="invalid-feedback">Email address already exists.</div>`,
                        verified: response.data === false
                    }
                }
            });
        }).catch(error => console.log(error));
    };

    register = () => {
        this.setState({
            isRegisterError: {
                verified: false,
            },
            isError: {

                verified: false,
            },
        })
        let userObj = {
            username: this.state.userData.username.value,
            email: this.state.userData.email.value,
            name: this.state.userData.name.value,
            contact: this.state.userData.mobile.value
        };

        service.registerUser(userObj).then(response => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    uuid: {
                        value: response.data.uuid,
                        message: response.data.message,
                        verified: true,
                    }
                });
            }
            this.setState({ showOtpInput: true, countdown: 60, otpClicked: true, otpButtonDisabled: true });

            const timer = setInterval(() => {
                this.setState((prevState) => {
                    if (prevState.countdown <= 1) {
                        clearInterval(timer);
                        return { otpButtonDisabled: false, countdown: 0 };
                    }
                    return { countdown: prevState.countdown - 1 };
                });
            }, 1000);

            // Disable OTP button for 1 minute
            setTimeout(() => {
                this.setState({ otpButtonDisabled: false });
            }, 60000);

        }, (err) => {
            this.setState({
                isRegisterError: {
                    verified: true,
                    value: err.response?.data?.message || 'An unexpected error occurred. Please try again.',
                },
            });
        }).catch((error) => {
            console.error(error);
            this.setState({
                isRegisterError: {
                    verified: true,
                    value: error.response?.data?.message || 'An unexpected error occurred. Please try again.',
                },
            });
        });
    };

    loginUser = () => {
        this.setState({
            isError: {
                verified: false,
            },
            isRegisterError: {
                verified: false,
            },
            isDisabled: true,
        });

        service.validateOtpRegister(this.state.userData.otp.value, this.state.uuid.value).then((response) => {
            console.log("Validate res", response);
            var keycloak = {
                access_token: response.data.token,
                "token_type": "bearer",
                "not-before-policy": 0,
                "session_state": "dfc35da7-b677-45f8-a0ef-c48ba9c67dea",
                "scope": "email profile"
            }


            // Store in localStorage
            localStorage.setItem('keycloak', JSON.stringify(keycloak));

            // Dispatch the loginUser action
            this.props.loginUser(keycloak);
            this.props.hideLoginWindow();
        }, (err) => {
            console.log("Unable to acquire auth token");
            this.setState({
                isError: {
                    verified: true,
                    value: err.response?.data?.message || 'An unexpected error occurred. Please try again.',
                },
                isDisabled: false,
            });
        }).catch((error) => {
            console.error(error);
            this.setState({
                isError: {
                    verified: true,
                    value: error.response?.data?.message || 'An unexpected error occurred. Please try again.',
                },
            });
        });

    }
    editInfo = () => {
        this.setState({
            ...this.state,
            otpClicked: false,
        })
    }

    handleChange = (e, field) => {
        let tmpState = { ...this.state };
        tmpState.userData[field].value = e.target.value;
        tmpState.userData[field].touched = true;
        tmpState.userData[field].error = '';
        this.setState(tmpState);
    };

    render() {
        return (
            <React.Fragment>
                <H2Title value="Let's get started" />

                {this.state.isError.verified && (
                    <div className="mt-3">
                        <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                        >
                            <strong>{this.state.isError.value}!</strong>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )}
                {this.state.isRegisterError.verified && (
                    <div className="mt-3">
                        <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                        >
                            <strong>{this.state.isRegisterError.value}</strong>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )}
                {this.state.uuid.verified && (
                    <div className="mt-3">
                        <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                        >
                            <strong>{this.state.uuid.message}!</strong>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )}

                {!this.state.otpClicked && (
                    <div>
                        <div className="form-group mt-3">
                            <input
                                value={this.state.userData.email.value}
                                onChange={(e) => this.handleChange(e, 'email')}
                                type="email"
                                onBlur={this.checkEmail}
                                className={this.state.userData.email.verified ? 'form-control is-valid' : this.state.userData.email.error.length === 0 ? 'form-control' : 'form-control is-invalid'}
                                placeholder="Enter email"
                            />
                            {ReactHtmlParser(this.state.userData.email.error.toString())}
                        </div>

                        <div className="form-group">
                            <input
                                value={this.state.userData.username.value} onChange={(e) => this.handleChange(e, 'username')} type="text"
                                onBlur={this.checkUsername}
                                className={this.state.userData.username.verified ? 'form-control is-valid' : this.state.userData.username.error.length === 0 ? 'form-control' : 'form-control is-invalid'}
                                id="username" placeholder="Username" />
                            {ReactHtmlParser(this.state.userData.username.error.toString())}
                        </div>

                        <div className="form-group">
                            <input value={this.state.userData.name.value} onChange={(e) => this.handleChange(e, 'name')} type="text"
                                onBlur={this.validateName}
                                className={this.state.userData.name.verified ? 'form-control is-valid' : this.state.userData.name.error.length === 0 ? 'form-control' : 'form-control is-invalid'}
                                id="name" placeholder="Name" />
                            {ReactHtmlParser(this.state.userData.name.error.toString())}
                        </div>
                        <div className="form-group">
                            <input value={this.state.userData.mobile.value} onChange={(e) => this.handleChange(e, 'mobile')} type="text"
                                onBlur={this.validateMobile}
                                className={this.state.userData.mobile.verified ? 'form-control is-valid' : this.state.userData.mobile.error.length === 0 ? 'form-control' : 'form-control is-invalid'}
                                id="mobile" placeholder="Mobile No."
                                maxLength={10} />
                            {ReactHtmlParser(this.state.userData.mobile.error.toString())}
                        </div>

                        <button type="button" onClick={this.register} className="btn btn-primary btn-sm btn-block">Register</button>
                    </div>
                )}

                {this.state.otpClicked && (
                    <div className="form-group">
                        <input
                            value={this.state.userData.otp.value}
                            onChange={(e) => this.handleChange(e, 'otp')}
                            type="text"
                            className="form-control"
                            placeholder="Enter OTP"
                            maxLength={8}
                        />
                        <button type="button" onClick={this.loginUser} className="btn btn-primary btn-sm btn-block mt-2">Verify OTP</button>
                        <button type="button"
                            onClick={this.register}
                            className="btn btn-secondary btn-sm btn-block mt-2"
                            disabled={this.state.otpButtonDisabled}>
                            {this.state.countdown > 0
                                ? `Resend OTP in (${this.state.countdown}s)`
                                : "Resend OTP"}</button>
                        <button type="button" onClick={this.editInfo} className="btn btn-light btn-sm btn-block mt-1">Back</button>
                    </div>
                )}

                <button type="button" onClick={() => this.props.changeScreen('login')} className="btn btn-light btn-sm btn-block">Sign In</button>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (currentState) => {
    return {
        userState: currentState.userState,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        loginUser: (data) => {
            dispatch({ type: 'user.auth.login', data: data });
        },
        hideLoginWindow: (data) => {
            dispatch({ type: 'login.window.hide', data: data });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(RegisterComponent);