import React, { Component } from 'react';
import MdBasket from 'react-ionicons/lib/MdBasket';
import MdOptions from 'react-ionicons/lib/MdOptions';
import MdSubway from 'react-ionicons/lib/MdSubway';
import IosRibbonOutline from 'react-ionicons/lib/IosRibbonOutline';
import IosRetrunOutline from 'react-ionicons/lib/IosReturnLeft';
import IosSettingsOutline from 'react-ionicons/lib/IosSettingsOutline';
import IosChatbubblesOutline from 'react-ionicons/lib/IosChatbubblesOutline';
import IosConstructOutline from 'react-ionicons/lib/IosConstructOutline';



class WhatWeDoComponent extends Component {

    state = {
        iconHeight: '36px',
        fontHeight: '16px'
    }

    adjustIcons = () => {
        if (document.body.clientWidth < 500) {
            this.setState({
                ...this.state,
                iconHeight: '24px',
                fontHeight: '13px'
            })
        }
    }

    getIcon = (iconName) => {
        let icon = null;
        switch (iconName) {
            case "ribbon": icon = (<IosRibbonOutline fontSize={this.state.iconHeight} color="#333" />); break;
            case "extension": icon = (<IosRetrunOutline fontSize={this.state.iconHeight} color="#333" />); break;
            case "construct": icon = (<IosConstructOutline fontSize={this.state.iconHeight} color="#333" />); break;
            case "settings": icon = (<IosSettingsOutline  fontSize={this.state.iconHeight} color="#333" />);break;
            case "chatbubbles": icon = (<IosChatbubblesOutline fontSize={this.state.iconHeight} color="#333" />); break;
            default: return null;
        }
        return icon;
    }

    componentDidMount() {
        this.adjustIcons();
    }

    render() {
        return (
           <div className="col-12 col-md-2 col-lg-2 equal" style={{ flex: "0 0 20%", maxWidth: "20%" }}>
    <div className="row p-2 m-1">
        <div className="col-12">
            {this.getIcon(this.props.icon)}
        </div>
        <div className="col-12" style={{ color: '#333', fontWeight: 'bold', fontSize: this.state.fontHeight }}>
            <span>{this.props.quality}</span>
        </div>
    </div>
</div>
        );
    }
}

export default WhatWeDoComponent;