import React, { Component } from "react";

import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";
import IosArrowDown from "react-ionicons/lib/IosArrowDown";
import * as endpoint from "./../../../Service/endpoints";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

class MainSidebar extends Component {
  state = {
    searchElem: "",
  };

  searchItem = () => {
    window.location.href =
      window.location.origin + "/search/" + this.state.searchElem;
  };

  logout = () => {
    delete localStorage.keycloak;
    window.location.href = "./";
  };

  closeMenu = () => {
    this.props.closeUserMenu();
  };

  signUp = () => {
    this.props.closeUserMenu();
    this.props.showLoginModal();
  };

  onSearchSelect = (event) => {
    this.setState(
      {
        ...this.state,
        searchElem: event.target.value,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  render() {
    return (
      <nav id="sidebar" className={this.props.userMenu.show ? "active" : ""}>
        <div id="dismiss" onClick={this.closeMenu}>
          <MdCloseCircle color="#f8f9fa" />
        </div>

        <div className="sidebar-header">
          <h3>Voorent</h3>
        </div>

        <div className="row m-auto">
          <div className="col-5" style={{ whiteSpace: "nowrap" }}>
            Current City
          </div>
          <div className="col-7">
            <div
              className="text-capitalize"
              onClick={this.props.selectCityOpen}
            >
              <span>
                {localStorage.city} &nbsp;
                <IosArrowDown color="#999" />
              </span>
            </div>
          </div>
        </div>

        <div className="row ml-2 mr-2  mt-2">
          <input
            type="search"
            size="25"
            onChange={this.onSearchSelect}
            className="form-control "
            placeholder="Search for products...."
            style={{ border: "none", background: "#F5F9FC" }}
          />

          <button
            className="btn btn-light btn-sm mt-1 col-12"
            onClick={this.searchItem}
          >
            Search
          </button>
        </div>

        <ul className="list-unstyled components">
          <li>
            <a href={endpoint.getRoute("")}>Home</a>
          </li>
          <li>
            <a href={endpoint.getRoute("furniture")}>Furniture</a>
          </li>
          <li>
            <a href={endpoint.getRoute("appliance")}>Appliance</a>
          </li>
          <li>
            <a href={endpoint.getRoute("package")}>Package</a>
          </li>
          <li className="nav-item" style={{fontSize:'16px'}}>
                <a className="nav-link" href={"/customization"}>Customization</a>
                </li>
          <li>
            <a href="https://parvhomes.com/">Buy Refurbished</a>
          </li>
          <li>
            {this.props.userState.loggedIn && (
              <a href={endpoint.getRoot("account")}>My Account</a>
            )}
          </li>
        </ul>

        <ul className="list-unstyled CTAs">
          <li>
            {!this.props.userState.loggedIn && (
              <a
                onClick={this.signUp}
                href="#"
                className="btn btn-light btn-sm btn-block p-1"
              >
                Login / SignUp
              </a>
            )}
          </li>

          <li>
            {this.props.userState.loggedIn && (
              <a href="#" className="btn btn-light btn-sm btn-block p-1">
                {" "}
                Change Password
              </a>
            )}
          </li>
          <li>
            {this.props.userState.loggedIn && (
              <a onClick={this.logout} className="article cursor-pointer">
                Logout
              </a>
            )}
          </li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (currentState) => {
  return {
    userMenu: currentState.userMenu,
    userState: currentState.userState,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    showLoginModal: () => {
      dispatch({ type: "login.window.show" });
    },
    closeUserMenu: () => {
      dispatch({ type: "user.menu.hide" });
    },
    selectCityOpen: () => {
      dispatch({ type: "user.city.show" });
    },
  };
};

export default connect(mapStateToProps, mapActionsToProps)(MainSidebar);
