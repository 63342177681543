import React, { Component } from 'react';
import HeaderComponent from '../../Generic/Header/HeaderComponent';
import FooterComponent from '../../Generic/Footer/FooterComponent';
import BreadCrumbs from '../../Generic/BreadCrumbs/BreadCrumbs';
import Customization from './Customization/Customization';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import H2Title from '../../Generic/Element/Heading';
import { Button } from 'react-bootstrap';

class CustomizationComponent extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: './' }, { name: "Customization", link: '#' }],
            showSidebar: false,
            sidebar: []
        });
    }

    showLoginModal = () => {
        if (this.props.userState.loggedIn) {
          window.location.href = "/account";
        } else {
          this.props.showLoginModal();
        }
      };
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <link rel='canonical' href="https://voorent.com/customization" />
                </Helmet>
                <HeaderComponent />
                <BreadCrumbs />
                <main role='main' className='container mt-5'>
                    <div className='row m-auto'>
                        <H2Title value={'Customization'} />
                        <Customization />

                        <Button
                            className=" btn-btn btn-danger mt-4"
                            onClick={this.showLoginModal}
                        >
                            Register
                        </Button>
                    </div>
                </main>
                <FooterComponent />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
      userState: currentState.userState,
    };
  };

const mapActionsToProps = (dispatch) => {
    return {
        showLoginModal: () => {
            dispatch({ type: "login.window.show" });
        },
        loginUser: (data) => {
            dispatch({ type: "user.auth.login", data: data });
        },
        updateUserInfo: (data) => {
            dispatch({ type: "login.update.userInfo", data: data });
        },
        openUserMenu: () => {
            dispatch({ type: "user.menu.show" });
        },
        selectCityOpen: () => {
            dispatch({ type: "user.city.show" });
        },
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(CustomizationComponent);