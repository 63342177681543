import React, { Component } from "react";
import Slider from "react-slick";

class BannerSliderComponent extends Component {
  componentWillReceiveProps() {
    //  this.refs.slick.innerSlider.onWindowResized()
  }

  render() {
    const settings = {
      className: "center",
      centerMode: true,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      centerPadding: "40px",
      autoplay: true,
      speed: 500,
      autoplaySpeed: 2000,
      cssEase: "linear",
      pauseOnHover: true,
    };
    return (
      // <div>
      //     <Slider {...settings}>
      //         <div style={{border:'2px solid #DDD'}}>
      //             <img src="http://voorent-new.s3-website.ap-south-1.amazonaws.com/images/two.jpeg" alt="Third slide" />
      //         </div>
      //         <div style={{border:'2px solid #DDD'}}>
      //             <img src="http://voorent-new.s3-website.ap-south-1.amazonaws.com/images/four.jpeg" alt="Third slide" />
      //         </div>
      //         <div style={{border:'2px solid #DDD'}}>
      //             <img src="http://voorent-new.s3-website.ap-south-1.amazonaws.com/images/two.jpeg" alt="Third slide" />
      //         </div>
      //         <div style={{border:'2px solid #DDD'}}>
      //             <img src="http://voorent-new.s3-website.ap-south-1.amazonaws.com/images/three.jpeg" alt="Third slide" />
      //         </div>
      //     </Slider>
      // </div>

      <div
        id="carouselExampleIndicators"
        className="carousel slide m-auto"
        data-ride="carousel"
        style={{ width: "100%", paddingTop: "80px" }}
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>

        <div className="carousel-inner">
          {
            /*
        <div className="carousel-item active">
          
                <img
                      style={{ minHeight: "220px" }}
                      className="d-block w-100 lazyload"
                      src="https://voorent.com/voorent-storage/images/50%25+off+offer+banner+updated.jpg"
                      alt="First slide"></img>
                </div> 
        <div className="carousel-item">
                <img
                      style={{ minHeight: "220px" }}
                      className="d-block w-100 lazyload"
                      src="https://voorent.com/voorent-storage/banners/voorent-banner-1.jpg"
                      alt="zeroth slide"></img>
                </div>*/

          }
          <div className="carousel-item active">
            <img
              style={{ minHeight: "220px" }}
              className="d-block w-100 lazyload"
              src="https://voorent.com/voorent-storage/banners/voorent-banner-1.jpg"
              alt="First slide"
            />
          </div>
          <div className="carousel-item">
  <img
    style={{ minHeight: "220px", width: "100%", height: "auto" }}
    className="d-block w-100"
    loading="lazy"
    src="https://voorent.com/voorent-storage/banners/voorent-banner-2.png"
    srcSet="
      https://voorent.com/voorent-storage/banners/voorent-banner-2-small.png 400w,
      https://voorent.com/voorent-storage/banners/voorent-banner-2-medium.png 768w,
      https://voorent.com/voorent-storage/banners/voorent-banner-2-large.png 1200w,
      https://voorent.com/voorent-storage/banners/voorent-banner-2-xlarge.png 1920w
    "
    sizes="
      (max-width: 650px) 100vw,
      (max-width: 1024px) 90vw,
      (max-width: 1440px) 70vw,
      50vw
    "
    alt="Second slide"
  />
</div>
<div className="carousel-item">
  <img
    style={{ minHeight: "220px", width: "100%", height: "auto" }}
    className="d-block w-100"
    loading="lazy"
    src="https://voorent.com/voorent-storage/banners/voorent-banner-3.png"
    srcSet="
      https://voorent.com/voorent-storage/banners/voorent-banner-3-small.png 520w,
      https://voorent.com/voorent-storage/banners/voorent-banner-3-medium.png 768w,
      https://voorent.com/voorent-storage/banners/voorent-banner-3-large.png 1080w,
      https://voorent.com/voorent-storage/banners/voorent-banner-3-xlarge.png 1920w
    "
    sizes="
      (max-width: 650px) 100vw,
      (max-width: 1024px) 90vw,
      (max-width: 1440px) 70vw,
      50vw
    "
    alt="Third slide"
  />
</div>


        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}

export default BannerSliderComponent;
