import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class Customization extends Component {
    state = {
        termsAndConditions: {
            id: 'Terms and conditions',
            value: [
                {
                    title: 'Eligibility and Scope',
                    content: 'Our rental services are exclusively available to co-working spaces, co-living communities, corporate clients, and working professionals.'
                },
                {
                    title: 'Pricing',
                    content: 'The minimum monthly ticket price for customization services is INR 15,000. This applies to all categories of products offered.'
                },
                {
                    title: 'Lock-in Period',
                    content: 'A mandatory lock-in period of 24 months (2 years) is applicable from the date of contract initiation. Early termination of the contract may incur additional charges as outlined in our cancellation policy.'
                }
            ]
        },
        perksOrBenefits: {
            id: 'Perks or benefits',
            value: [
                {
                    title: 'No Security Deposit Required',
                    content: 'Clients are not required to pay any security deposit to access our these services, ensuring a hassle-free and cost-effective experience.'
                },
                {
                    title: ' Product Customization',
                    content: 'Customers have the flexibility to customize rented products to meet their specific needs and preferences. This ensures that our products align perfectly with their lifestyle or workspace requirements.'
                },
                {
                    title: 'Rent-to-Own Feature',
                    content: 'Our rent-to-own option allows clients to gradually transition towards product ownership. Payments made over the rental period can contribute towards the purchase of the item. Terms and conditions for this feature will be specified in the individual rental agreement.'
                },
                {
                    title: 'Brand New, Unused Products',
                    content: 'All rented products are brand new and unused, guaranteeing the highest quality and performance for our clients.'
                }
            ]
        },
        showRegister: false,

    };

    render() {
        return (
            <React.Fragment>
                <div className="col-12">

                    <h3 className="mt-4" style={{color:'#004a80'}}>{this.state.termsAndConditions.id}</h3>
                    {this.state.termsAndConditions.value.map((item, index) => (
                        <div key={index} className="col-12 mt-3">
                            <span className="d-block font-weight-bold" style={{fontSize:18}}>
                                { item.title}
                            </span>
                            <p>➤    {item.content}</p>
                        </div>
                    ))}

                    <h3 className="mt-4" style={{color:'#004a80'}}>{this.state.perksOrBenefits.id}</h3>
                    {this.state.perksOrBenefits.value.map((item, index) => (
                        <div key={index} className="col-12 mt-3">
                            <span className="d-block font-weight-bold" style={{fontSize:18}}>
                                {item.title}
                            </span>
                            <p>➤    {item.content}</p>
                        </div>
                    ))}
                    
                </div>
            </React.Fragment>
        );
    }
}

export default Customization;