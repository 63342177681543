import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import "./../../../styles/login-style.css";

import { connect } from 'react-redux';
import LoginComponent from './Login/LoginComponent';
import RegisterComponent from './Register/RegisterComponent';
import ForgotComponent from './Forgot/ForgotComponent';


class LoginWrapper extends Component {

    state = {
        showModal : false,
        currentScreen : 'login',
        userData : {
            username : '',
            otp : '',
            uuid:'',
        }
    }

    updateState = (state) => {
        this.setState({
            ...state
        });
    };


    showLogin = () => {
        this.setState({
            currentScreen : 'login',
            showModal : true
        })
    }

    onBackdrop = () => {
       this.props.hideLoginWindow({show : false});
    }

    changeCurrentScreen = (screen) => {
        this.setState({
            ...this.state,
            currentScreen : screen
        });
    }

    getComponent = () => {
        let renderComponent = null;
        switch(this.state.currentScreen){
            case 'login' : renderComponent = (<LoginComponent state={this.state} updateState = {this.updateState} changeScreen={this.changeCurrentScreen} />); break;
            case 'register' : renderComponent = (<RegisterComponent updateState = {this.updateState} changeScreen={this.changeCurrentScreen} />); break;
            case 'forgot' : renderComponent = (<ForgotComponent updateState = {this.updateState} changeScreen={this.changeCurrentScreen} />); break;
            default : renderComponent = (<LoginComponent updateState = {this.updateState} changeScreen={this.changeCurrentScreen}/>);
        }

        return renderComponent;
    }

    render() {
        return (
            <Modal visible={this.props.userState.showLogin} onClickBackdrop={this.onBackdrop}>
                <div className="modal-body pt-0 pb-0" style={{height:'inherit'}}>
                    <div className="row" style={{height:'100%'}}>
                        <div className="col-5 col-md-6 d-none d-md-block" style={{minHeight:'100%',overflow: 'hidden', background:'#eff2f7'}}>
                                <img className="lazyload" src="https://voorent.com/voorent-storage/images/login.d814adb7.png" alt="" style={{marginTop: '30%'}} />
                        </div>
                        <div className="col-12 col-md-6 p-5 mb-auto mt-auto position-relative" style={{minHeight:'450px'}}>
                            {
                                this.getComponent()
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

}

const mapStateToProps = (currentState) => {
    return {
        userState: currentState.userState,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        hideLoginWindow: (data) => {
            dispatch({ type: 'login.window.hide', data: data });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(LoginWrapper);
