import React, { Component } from 'react';
import * as service from './../../../Service/service';

class SuccessComponent extends Component {

    componentDidMount(){
        // service.orderMail(this.props.order.orderNumber).then(response => {
        //     console.log(response.data);
        // }, error => {
        //     console.log(error);
        // })
    }

    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-12 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="128px" height="128px"><g id="Shopping_bag" data-name="Shopping bag"><path d="M480,88H408V48A40.045,40.045,0,0,0,368,8H272a40.045,40.045,0,0,0-40,40V88H160a8,8,0,0,0-8,8V208H40a24.028,24.028,0,0,0-24,24V376a24.028,24.028,0,0,0,24,24H152v64a40.045,40.045,0,0,0,40,40H448a40.045,40.045,0,0,0,40-40V96A8,8,0,0,0,480,88ZM248,48a24.028,24.028,0,0,1,24-24h96a24.028,24.028,0,0,1,24,24V88H248ZM32,232a8.009,8.009,0,0,1,8-8H280a8.009,8.009,0,0,1,8,8v8H32Zm0,24H288v24H32Zm0,120V296H288v80a8.009,8.009,0,0,1-8,8H40A8.009,8.009,0,0,1,32,376Zm248,24a24.028,24.028,0,0,0,24-24V232a24.028,24.028,0,0,0-24-24H168V104h64v32a8,8,0,0,0,16,0V104H392v32a8,8,0,0,0,16,0V104h64V432H168V400Zm192,64a24.027,24.027,0,0,1-24,24H192a24.027,24.027,0,0,1-24-24V448H472Z" /><path d="M352,472h72a8,8,0,0,0,0-16H352a8,8,0,0,0,0,16Z" /><path d="M448,472h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z" /><path d="M104,304H56a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V312A8,8,0,0,0,104,304Zm-8,48H64V320H96Z" /><path d="M192,304H128a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Z" /><path d="M192,328H128a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Z" /><path d="M144,352H128a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Z" /><path d="M176,352h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z" /></g></svg>

                    </div>
                    {
                       (this.props.order.isMedical != true) &&
                       ( <div className="col-12 text-center mt-2">
                            <h3 className="text-uppercase">Thank You for your Order</h3>
                            <h6 className="d-block">Order Number : {this.props.order.orderNumber}</h6>
                            <h6 className="d-block">Your Order summary is sent to your registered email Id.</h6>
                            <h6>Please complete your KYC by logging to your Account {'>'} KYC. <br></br> We will ship the items once KYC is completed.</h6><br></br>
                            <h6><strong>NOTE: </strong> Please mail your landlord/Any 2 local references contact details and address to <a href="mailto:support@voorent.com">support@voorent.com</a> while completing your KYC. Orders are accepted after verification of this data.</h6>
                        </div>)
                    }
                    {
                        (this.props.order.isMedical == true) &&
                        (
                            <div className="col-12 text-center mt-2">
                                <h3 className="text-uppercase">Thank You for your Enquiry</h3>
                                <h6 className="d-block">Order Number : {this.props.order.orderNumber}</h6>
                                <h6 className="d-block">Our team will contact you within 24 hrs.</h6>
                           </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default SuccessComponent;